import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ALL_SITES } from '@tempus/t-shared';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';

import AddPatient from '../AddPatientV2';
import { useStyles } from './styles';

interface PatientTrackerHeaderProps {
  storeKeys: StoreKeys;
  title: string;
  siteId: string;
}

const PatientTrackerHeader: React.FC<PatientTrackerHeaderProps> = ({ storeKeys, title }) => {
  const classes = useStyles();
  const { canWritePatientTracking } = useSelector((state: RootState) => state.user);
  const selectedUserSite = useSelector((state: RootState) => state.site.selectedUserSite);
  const isAllSitesSelected = selectedUserSite?.name == ALL_SITES;
  return (
    <div className={classes.header}>
      <span className={`${typography.header} ${classes.title}`} data-testid="patient-tracker-heading">
        {title}
      </span>
      <div className={classes.actionButtons}>
        {canWritePatientTracking && !isAllSitesSelected && <AddPatient storeKeys={storeKeys} />}
      </div>
    </div>
  );
};

export default PatientTrackerHeader;
