import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownOption } from 'tcl-v3/models';

import InputFieldHelperText from '~/components/UpdateOverlay/DatePickerHelperText';
import {
  dateRuleInFutureWarning,
  dateRuleNotInFuture,
  dateRuleRequired,
  dateRuleRequiredWarning,
  FieldStatus,
  getChangedValues,
  getFormattedDateForPicker,
  validateDateByRules,
} from '~/components/UpdateOverlay/utils';
import { RootState } from '~/store';
import { TrialMatchUpdates } from '~/store/api/types';
import { getVisitTypeDropdownOptions } from '~/store/patientTrackerCommons/constants';
import { SyncedFields } from '~/store/patientTrackerCommons/types';
import { formatDateForDisplay } from '~/utils/misc';

import useStyles from './styles';

interface ConsentedProps {
  consentDate: string;
  consentRecognizedDate: string;
  syncedFields: SyncedFields;
  setSyncedFields: (syncedFields: SyncedFields) => void;

  nextVisitType: string;
  nextVisitDate: string;
  handleChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const Consented: React.FC<ConsentedProps> = ({
  consentDate,
  consentRecognizedDate,
  syncedFields,
  setSyncedFields,
  nextVisitType,
  nextVisitDate,
  handleChanges,
  setAreRequiredFieldsMissing,
}) => {
  const classes = useStyles();
  const selectedConsentDate = syncedFields.consentDate;

  const isInternalUser = useSelector(
    (state: RootState) => state.user.canViewAllPatientTracking && state.user.canWritePatientTracking,
  );

  const [selectedConsentRecognizedDate, setSelectedConsentRecognizedDate] = useState<string | null>(
    consentRecognizedDate,
  );

  const validateConsentDates = (date: string) => validateDateByRules(date, [dateRuleRequired, dateRuleNotInFuture]);
  const [consentDateValidation, setConsentDateValidation] = useState(validateConsentDates(selectedConsentDate || ''));

  const [consentRecognizedDateValidation, setConsentRecognizedDateValidation] = useState(
    validateConsentDates(selectedConsentRecognizedDate || ''),
  );

  const [selectedNextVisitType, setNextVisitType] = useState<DropdownOption | null>(
    nextVisitType ? { value: nextVisitType, label: nextVisitType } : null,
  );

  const [selectedNextVisitDate, setNextVisitDate] = useState<string | null>(nextVisitDate);
  const validateNextVisitDate = (date: string) =>
    validateDateByRules(date, [dateRuleRequiredWarning, dateRuleInFutureWarning]);

  const [nextVisitDateValidation, setNextVisitDateValidation] = useState(
    validateNextVisitDate(selectedNextVisitDate || ''),
  );

  const setSelectedConsentDate = (date: string | null) => {
    setSyncedFields({ ...syncedFields, consentDate: date });
  };

  const getInitialValues = () => {
    return {
      patientConsentedDate: consentDate ? new Date(consentDate).toISOString() : null,
      dateConsentRecognizedByTempus: consentRecognizedDate ? new Date(consentRecognizedDate).toISOString() : null,
      nextVisitType,
      nextVisitDate: formatDateForDisplay(nextVisitDate, true),
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      patientConsentedDate: selectedConsentDate ? new Date(selectedConsentDate).toISOString() : null,
      dateConsentRecognizedByTempus: selectedConsentRecognizedDate
        ? new Date(selectedConsentRecognizedDate).toISOString()
        : null,
      nextVisitType: selectedNextVisitType?.value || null,
      nextVisitDate: formatDateForDisplay(selectedNextVisitDate, true),
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleChanges(changedValues);
    const selectedConsentDateValidation = validateConsentDates(selectedConsentDate || '');
    setConsentDateValidation(selectedConsentDateValidation);
    const selectedConsentRecognizedDateValidation = validateConsentDates(selectedConsentRecognizedDate || '');
    setConsentRecognizedDateValidation(selectedConsentRecognizedDateValidation);
    const selectedNextVisitDateValidation = validateNextVisitDate(selectedNextVisitDate || '');
    setNextVisitDateValidation(selectedNextVisitDateValidation);

    const areRequiredFieldsMissing =
      !selectedConsentDateValidation.passed || (isInternalUser && !selectedConsentRecognizedDateValidation.passed);

    setAreRequiredFieldsMissing(areRequiredFieldsMissing);
  }, [selectedConsentDate, selectedConsentRecognizedDate, selectedNextVisitType, selectedNextVisitDate]);

  return (
    <>
      <div className={classes.twoColumns}>
        <div>
          <SingleDatePicker
            label="Consent date"
            status={consentDateValidation.status}
            onChange={(date) => setSelectedConsentDate(getFormattedDateForPicker(date.dateString))}
            value={{ dateString: moment.utc(selectedConsentDate).format('MM/DD/YYYY') }}
          />
          <InputFieldHelperText status={consentDateValidation.status} message={consentDateValidation.message} />
        </div>
        {isInternalUser && (
          <div>
            <SingleDatePicker
              label="Consent recognized date"
              status={consentRecognizedDateValidation.status}
              onChange={(date) => setSelectedConsentRecognizedDate(getFormattedDateForPicker(date.dateString))}
              value={{ dateString: moment.utc(selectedConsentRecognizedDate).format('MM/DD/YYYY') }}
            />
            <InputFieldHelperText
              status={consentRecognizedDateValidation.status}
              message={consentRecognizedDateValidation.message}
            />
          </div>
        )}
      </div>
      <div className={classes.divider}></div>
      <div>Review again after next visit</div>
      <div className={cn(classes.subsequentRow, classes.twoColumns)}>
        <div>
          <SingleSelectDropdown
            onChange={(opt) => setNextVisitType(opt)}
            value={selectedNextVisitType}
            label="Next visit type"
            options={getVisitTypeDropdownOptions()}
            escapeClippingArea
            status={!selectedNextVisitType ? 'warning' : 'default'}
          />
          <InputFieldHelperText
            status={!selectedNextVisitType ? FieldStatus.WARNING : FieldStatus.DEFAULT}
            message={'Type is missing'}
          />
        </div>

        <div>
          <SingleDatePicker
            label="Next visit date"
            status={nextVisitDateValidation.status}
            onChange={(date) => setNextVisitDate(getFormattedDateForPicker(date.dateString))}
            value={{ dateString: moment.utc(selectedNextVisitDate).format('MM/DD/YYYY') }}
          />
          <InputFieldHelperText status={nextVisitDateValidation.status} message={nextVisitDateValidation.message} />
        </div>
      </div>
    </>
  );
};

export default Consented;
