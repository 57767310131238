import { Close, Save } from '@tcl-boron-icons/icons';
import Loader from '@tcl-boron-prefabs/loader';
import { PatientCohortMatchWorkflowEventType, PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import { TimeProgramSiteStatus } from '@tempus/t-shared';
import { storeActions } from '@tempus/t-shared/ui';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '~/store';
import api from '~/store/api';
import { TrialMatchUpdates, UpdateTimePatientV2 } from '~/store/api/types';
import { internalControlFields, relevantFieldsForStatus } from '~/store/patientTrackerCommons/constants';
import { trialStatusToDisplay } from '~/store/patientTrackerCommons/helpers';
import { PatientTrackingDetails, SyncedFields, TimePatient } from '~/store/patientTrackerCommons/types';

import { InternalControls } from './InternalControls';
import MatchOverview from './MatchOverview';
import MatchStatusUpdate from './MatchStatusUpdate';
import WhyThisStatus from './WhyThisStatus';
import useStyles from './styles';

const whyThisStatusIneligibleStatuses = [
  PatientCohortMatchWorkflowState.INTERNAL_REVIEW,
  PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW,
  PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
];

interface TrialMatchUpdateProps {
  stateMapKey: string;
  timePatientId: string;
  ptd: PatientTrackingDetails;
  timePatientVisit?: TimePatient['timePatientVisits'][0];
  exit: () => void;
  areThereUnsavedUpdates: boolean;
  setAreThereUnsavedUpdates: (areThereUnsavedUpdates: boolean) => void;
  refreshData: () => void;
}

export const TrialMatchUpdate: React.FC<TrialMatchUpdateProps> = ({
  stateMapKey,
  timePatientId,
  ptd: originalPtd,
  timePatientVisit,
  exit,
  areThereUnsavedUpdates,
  setAreThereUnsavedUpdates,
  refreshData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isInternalUser = useSelector(
    (state: RootState) => state.user.canViewAllPatientTracking && state.user.canWritePatientTracking,
  );

  const [ptd, setPtd] = useState(originalPtd);
  const [showWhyThisStatus, setShowWhyThisStatus] = useState(false);
  const [newStatus, setNewStatus] = useState<PatientCohortMatchWorkflowState | null>(null);
  const [whyThisStatus, setWhyThisStatus] = useState<string>(''); // TODO: update sets

  const [syncedFields, setSyncedFields] = useState<SyncedFields>({
    consentDate: ptd.patientConsentedDate,
    enrolledDate: ptd.firstTreatmentDate || null,
  });

  const [trialMatchChanges, setTrialMatchChanges] = useState<Partial<TrialMatchUpdates>>({});
  const [areRequiredFieldsMissing, setAreRequiredFieldsMissing] = useState(false);
  const [saving, setSaving] = useState(false);

  const { loading: updatingTimePatientData, data: patientData } = useSelector(
    (state: RootState) => state.patientTrackerV3[stateMapKey],
  ).patient;

  useEffect(() => {
    if (!updatingTimePatientData) {
      setPtd(
        patientData?.patientTrackingDetails.find((ptd) => ptd.workflowId === originalPtd.workflowId) || originalPtd,
      );
      setSaving(false);
    }
  }, [updatingTimePatientData]);

  const removeIrrelevantTrialMatchUpdates = (
    updates: Partial<TrialMatchUpdates>,
    status: PatientCohortMatchWorkflowState,
  ) => {
    const updatedUpdates = { ...updates };

    for (const key in updatedUpdates) {
      if (
        !relevantFieldsForStatus[status].includes(key as keyof TrialMatchUpdates) &&
        (!isInternalUser || !internalControlFields.includes(key))
      ) {
        delete updatedUpdates[key];
      }
    }

    delete updatedUpdates.statusUpdateNote;
    return updatedUpdates;
  };

  useEffect(() => {
    setShowWhyThisStatus(Boolean(newStatus) && !whyThisStatusIneligibleStatuses.includes(newStatus!));

    const updatedTrialMatchChanges = removeIrrelevantTrialMatchUpdates(
      trialMatchChanges,
      newStatus || (ptd.status as PatientCohortMatchWorkflowState),
    );

    setTrialMatchChanges(updatedTrialMatchChanges);
    setAreThereUnsavedUpdates(!isEmpty(updatedTrialMatchChanges) || Boolean(newStatus));

    if (
      newStatus &&
      [PatientCohortMatchWorkflowState.INTERNAL_REVIEW, PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW].includes(
        newStatus,
      )
    ) {
      setAreRequiredFieldsMissing(false);
    }
  }, [newStatus]);

  const handleTrialMatchChanges = (changes: Partial<TrialMatchUpdates>) => {
    const updatedChanges = { ...trialMatchChanges, ...changes };

    for (const key of relevantFieldsForStatus[newStatus || (ptd.status as PatientCohortMatchWorkflowState)]) {
      if (updatedChanges[key] !== changes[key] && (!isInternalUser || !internalControlFields.includes(key))) {
        delete updatedChanges[key];
      }
    }

    setTrialMatchChanges(updatedChanges);
    setAreThereUnsavedUpdates(!isEmpty(updatedChanges) || Boolean(newStatus));
  };

  useEffect(() => {
    newStatus && handleTrialMatchChanges({ statusUpdateNote: whyThisStatus });
  }, [whyThisStatus]);

  const getEventType = (status: PatientCohortMatchWorkflowState | null): PatientCohortMatchWorkflowEventType => {
    switch (status) {
      case PatientCohortMatchWorkflowState.INTERNAL_REVIEW:
        return PatientCohortMatchWorkflowEventType.InternalReview;
      case PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW:
        return ptd.status === PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW
          ? PatientCohortMatchWorkflowEventType.Match
          : PatientCohortMatchWorkflowEventType.SendToSite;
      case PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE:
        return PatientCohortMatchWorkflowEventType.NotAMatch;
      case PatientCohortMatchWorkflowState.WATCHLIST:
        return PatientCohortMatchWorkflowEventType.MatchLater;
      case PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE:
        return PatientCohortMatchWorkflowEventType.ImminentCandidate;
      case PatientCohortMatchWorkflowState.PURSUING_ACTIVATION:
        return PatientCohortMatchWorkflowEventType.PursuingActivation;
      case PatientCohortMatchWorkflowState.CONSENTED:
        return PatientCohortMatchWorkflowEventType.Consented;
      case PatientCohortMatchWorkflowState.ENROLLED:
        return PatientCohortMatchWorkflowEventType.Enrolled;
      default:
        return PatientCohortMatchWorkflowEventType.SelfTransition;
    }
  };

  const save = async () => {
    const updates: UpdateTimePatientV2 = {};

    if (trialMatchChanges.nextVisitType || trialMatchChanges.nextVisitDate) {
      const nextVisitType = trialMatchChanges.nextVisitType || timePatientVisit?.visitType || null;
      const nextVisitDate = trialMatchChanges.nextVisitDate || timePatientVisit?.visitDate || null;

      if ((nextVisitType && nextVisitDate) || (!nextVisitType && !nextVisitDate)) {
        updates.visitType = nextVisitType;
        updates.visitDate = nextVisitDate;
      }
    }

    delete trialMatchChanges.nextVisitType;
    delete trialMatchChanges.nextVisitDate;

    if (!isEmpty(trialMatchChanges) || newStatus) {
      updates.trialMatches = [
        { ...trialMatchChanges, workflowId: ptd.workflowId, trialId: ptd.trial.id, eventType: getEventType(newStatus) },
      ];
    }

    setSaving(true);
    try {
      await api.timePatient.updateTimePatientV2(timePatientId, updates);
      dispatch(storeActions.notification.showSuccessMessage(`Updated match.`));
      refreshData();
      setTrialMatchChanges({});
      setNewStatus(null);
      exitFlow();
    } catch (error) {
      dispatch(storeActions.notification.showErrorMessage(`Failed to update trial match - ${error}`));
      setSaving(false);
    }
  };

  const exitFlow = () => {
    setAreThereUnsavedUpdates(false);
    exit();
  };

  return (
    <>
      <div className={classes.trialDetailsSection}>
        <div className={classes.twoColumnsV2}>
          <div className={classes.headerV2}>{ptd.trial?.shortName}</div>
        </div>
        <div className={classes.twoColumnsV2}>
          <div>{ptd.arm?.name}</div>
          <i>{trialStatusToDisplay(ptd.siteArm ? (ptd.siteArm?.status as TimeProgramSiteStatus) : undefined)}</i>
        </div>
      </div>
      <div className={classes.saveExit}>
        <div onClick={exitFlow} className={saving ? classes.disabled : ''}>
          <Close />
          <span>Exit</span>
        </div>
        <div
          onClick={save}
          className={areRequiredFieldsMissing || !areThereUnsavedUpdates || saving ? classes.disabled : ''}>
          <Save />
          <span>Save changes</span>
        </div>
      </div>
      {saving ? (
        <div className={classes.saving}>
          <Loader size="large" />
        </div>
      ) : null}
      <div className={classes.container} style={saving ? { display: 'none' } : {}}>
        <div className={classes.matchUpdateSection}>
          <MatchOverview ptd={ptd} />
          <MatchStatusUpdate
            ptd={ptd}
            timePatientVisit={timePatientVisit}
            newStatus={newStatus}
            setNewStatus={setNewStatus}
            syncedFields={syncedFields}
            setSyncedFields={setSyncedFields}
            handleTrialMatchChanges={handleTrialMatchChanges}
            setAreRequiredFieldsMissing={setAreRequiredFieldsMissing}
          />
          {showWhyThisStatus && (
            <WhyThisStatus
              status={(newStatus || ptd.status) as PatientCohortMatchWorkflowState}
              whyThisStatus={whyThisStatus}
              updateWhyThisStatus={setWhyThisStatus}
            />
          )}
          {isInternalUser && (
            <InternalControls
              ptd={ptd}
              syncedFields={syncedFields}
              setSyncedFields={setSyncedFields}
              handleTrialMatchChanges={handleTrialMatchChanges}
              newStatus={newStatus}
              setNewStatus={setNewStatus}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TrialMatchUpdate;
