import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  subsequentRow: {
    marginTop: '16px',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',

    '& > div': {
      gridColumns: 'span 1',
    },
  },
  divider: {
    height: '1px',
    width: '100%',
    margin: '16px 0',
    backgroundColor: `${GrayscalePalette[400]}`,
  },
  textArea: {
    marginTop: '16px',

    '& textarea': {
      width: '100%',
      height: '100px',
    },
  },
});
