import { MultiSelectDropdownProps } from '@tcl-boron-prefabs/multi-select-dropdown';
import {
  PatientCohortMatchWorkflowState,
  SnoozeData as StateflowSnoozeData,
  SnoozeDataType,
} from '@tempus/stateflow-types';
import {
  Activity,
  Institution,
  OperationalFlags,
  ProgramSite,
  TimePatientStatuses,
  TimeProgramSiteStatus,
  Trial,
  Summary,
} from '@tempus/t-shared';
import {
  PATIENT_TRACKER_STATUS_CATEGORIES,
  PATIENT_TRACKER_STATUSES,
} from '@tempus/t-shared/src/constants/patient-tracker';
import { DropdownOption } from 'tcl-v3/models';

import { PATIENT_TRACKER_V3_TAB, VisitType } from './constants';

export enum PatientTrackerStateId {
  PATIENT_TRACKER_V2 = 'patientTrackerV2',
  TRIAL_DOCUMENTS_PAGE_PATIENTS = 'trialDocumentsPagePatients',
  MATCH_REVIEW_PAGE = 'matchReviewPage',
  ALL_PATIENTS_PAGE = 'allPatientsPage',
  TEMPUS_NURSE_PAGE = 'tempusNursePage',
  MATERIALS_PAGE = 'materialsPage',
}

export interface StoreKeys {
  store: 'patientTrackerV2' | 'patientTrackerV3';
  stateMapKey: PatientTrackerStateId;
}

export interface PatientTrackerFilters {
  [PatientTrackerFilterField.SORT_ORDER]: DropdownOption;
  [PatientTrackerFilterField.TRIAL_NAME]: DropdownOption | null;
  [PatientTrackerFilterField.TRIAL_TYPE]: DropdownOption;
  [PatientTrackerFilterField.TRIAL_STATUS]: DropdownOption[];
  [PatientTrackerFilterField.PHYSICIAN]: string;
  [PatientTrackerFilterField.INDICATIONS]: DropdownOption[];
  [PatientTrackerFilterField.BIOMARKER]: string;
  [PatientTrackerFilterField.PATIENT_NAME]: string;
  [PatientTrackerFilterField.NOTE]: string;
  [PatientTrackerFilterField.PATIENT_ID]: string;
  [PatientTrackerFilterField.RN_ASSIGNMENT]: string;
  [PatientTrackerFilterField.MATCH_STATUS]: MultiSelectDropdownProps['value'];
}

export enum PatientTrackerFilterField {
  SORT_ORDER = 'sortOrder',
  TRIAL_NAME = 'trialName',
  TRIAL_STATUS = 'trialStatus',
  PHYSICIAN = 'physician',
  INDICATIONS = 'indications',
  BIOMARKER = 'biomarker',
  PATIENT_NAME = 'patientName',
  NOTE = 'note',
  TRIAL_TYPE = 'trialType',
  PATIENT_ID = 'patientId',
  RN_ASSIGNMENT = 'rnAssignment',
  MATCH_STATUS = 'matchStatus',
}

export const PatientTrackerSingleDropdownFilters = [
  PatientTrackerFilterField.SORT_ORDER,
  PatientTrackerFilterField.TRIAL_NAME,
  PatientTrackerFilterField.TRIAL_TYPE,
];

export const PatientTrackerMultiDropdownFilters = [
  PatientTrackerFilterField.INDICATIONS,
  PatientTrackerFilterField.TRIAL_STATUS,
  PatientTrackerFilterField.MATCH_STATUS,
];

export interface PatientTrackerState {
  fetchingRecords: boolean;
  siteId: string;
  tab: PATIENT_TRACKER_STATUS_CATEGORIES | PATIENT_TRACKER_V3_TAB;
  patients: TimePatient[];
  patientsMetadata: PatientRecordMetadata;
  categoryCounts: PatientTrackerTabCounts;
  updateModal: PatientRecordBrowsingState;
  editOverlay: PatientRecordBrowsingState;
  filters: PatientTrackerFilters;
  exports: PatientRecordExportState;
  patient: PatientRecordState;
  currentInstitutionPhysicians: Physician[] | null;
}

export type PatientTrackerStateMap = Record<string, PatientTrackerState>;

export interface PatientRecordBrowsingState {
  selectedPatientRecord: PatientRecord | null;
  isOpen: boolean;
}

export interface PatientRecordExportState {
  data?: string;
  fileName?: string;
  loading: boolean;
  error?: unknown;
}

export interface PatientRecordState {
  data?: TimePatient;
  loading: boolean;
  error?: unknown;
}

export interface PatientRecordMetadata {
  totalRecords: number;
  numRecords: number;
  skip: number;
  top?: number;
}

export interface PatientRecord {
  dateCreated: string;
  acknowledged: string;
  recordId: number;
  relatedPatientId: number;
  relatedTrialWorkflowId: number;
  patientDob: string;
  patientFullName: string;
  patientFirstName: string;
  patientIdentifiedBy: string;
  patientLastName: string;
  biomarker: string;
  matchDate: string;
  nctId: string;
  cohortId: string;
  cohortName: string;
  trialName: string;
  institutionId: string;
  latestUpdates: string;
  latestUpdatesModifiedAt: string;
  nextVisitDate: string | null;
  visitType: string;
  program: string;
  passedPharmaOpsReview: string;
  pharmaOpsReviewCompletedBy: string;
  pharmaOpsReviewCompletedDate: string;
  tAppNotes: string;
  timePatientId: string;
  timeSite: string;
  trial: Pick<Trial, 'id' | 'nctId' | 'shortName' | 'arms'> & { programSites: ProgramSite[] };
  patientMatchStatus: string;
}

export type DeprecatedTimePatient = Pick<
  PatientRecord,
  | 'recordId'
  | 'institutionId'
  | 'patientFirstName'
  | 'patientLastName'
  | 'patientDob'
  | 'patientIdentifiedBy'
  | 'program'
> & { timePatientId: string; timeSite: string };

export interface PatientTrackerTabCounts {
  [PATIENT_TRACKER_STATUS_CATEGORIES.NEW]?: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.PRIORITY]?: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.MONITORING]?: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.ENROLLED]?: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.INACTIVE]?: number | null;
  [PATIENT_TRACKER_STATUS_CATEGORIES.SCREENING_QUEUE]?: number | null;
  [PATIENT_TRACKER_V3_TAB.REVIEW_REQUIRED]?: number | null;
  [PATIENT_TRACKER_V3_TAB.WATCHLIST]?: number | null;
  [PATIENT_TRACKER_V3_TAB.CANDIDATE_NOW]?: number | null;
  [PATIENT_TRACKER_V3_TAB.SCREENING_QUEUE]?: number | null;
  [PATIENT_TRACKER_V3_TAB.ENROLLED]?: number | null;
  [PATIENT_TRACKER_V3_TAB.VISITING_THIS_WEEK]?: number | null;
  [PATIENT_TRACKER_V3_TAB.TIME_SENSITIVE]?: number | null;
}

export interface PatientRecordsFilters extends TableFilters {
  top?: number;
  skip?: number;
}
export interface PatientRecordsQuery {
  includeFilters?: PatientRecordsFilters;
  excludeFilters?: PatientRecordsFilters;
  sortBy?: string[];
}

export interface PatientTrackingDetailArms {
  id: string;
  name: string;
  operationalFlag: OperationalFlags;
}

type TableFilters = Partial<Record<keyof PatientRecord, string | string[] | number | number[]>>;

export interface PatientTrackingDetails {
  matchingFilters: boolean;
  id: string;
  recordId: number;
  patientId: string;
  patientLinkageId: string;
  reasonNotAMatch: string | null;
  reasonNotAMatchDetails: string | null;
  tempusMdNotes: string;
  portalToTempusNextVisit: string;
  portalToTempusNextVisitType: string;
  passedRnReview: string;
  trial: Pick<Trial, 'id' | 'nctId' | 'shortName' | 'title'> & { type: 'Observational' | 'Interventional' };
  arm?: PatientTrackingDetailArms;
  armSummary?: {
    summary: Summary;
    updatedAt: string;
  };
  siteArm: {
    status: TimeProgramSiteStatus;
  };
  tempusToPortalLatestPortalPatientUpdate: string | null;
  tempusToPortalLatestPortalUpdateModifiedDate: string;
  programSite?: Pick<ProgramSite, 'status' | 'programType'>;
  linkedTreatingMd: string;
  mutations?: string; // TODO: remove with pre-m3 deprecation
  institutionId: string;
  mrn?: string;
  patientCancerType: string;
  status: PATIENT_TRACKER_STATUSES | PatientCohortMatchWorkflowState; // TODO: remove with pre-m3 deprecation
  statusDate: string | null;
  timePatientId: string;
  rescreen: boolean;
  noCredit: boolean;
  dateRnClearedForNotification: string | null;
  dateNotificationSentToSite: string | null;
  patientConsentedDate: string | null;
  dateConsentRecognizedByTempus: string | null;
  firstTreatmentDate?: string | null;
  timeRnReviewCompletedDate?: string | null;
  screenedBy?: string | null;
  tAppNotes?: string | null;
  internalRnToScreeningRnNote?: string | null;

  workflowId: string;
  workflowType?: string;
  snoozeData?: StateflowSnoozeData[];
  snoozeType?: SnoozeDataType;
  snoozeDate?: string;
  currentState?: PatientCohortMatchWorkflowState;
  createdAt?: string;
  updatedAt?: string;
  urgentFlag?: boolean;
  newFlag?: boolean;
  cohortOnHoldFlag?: boolean;
  siteOnHoldFlag?: boolean;
  rescreenFlag?: boolean;
  biomarkers?: string | null;
}

export interface SnoozeData {
  type?: SnoozeDataType;
  date?: string;
}

export interface PatientTrackerPatient {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  timePatient: TimePatient;
  relatedPatientTrackingDetails: PatientTrackingDetails[];
}

export interface TimePatient {
  id: string;
  patientLinkageId: string;
  responseRequired: boolean;
  status: TimePatientStatuses;
  timePatientVisits: {
    visitDate: string;
    visitType: VisitType;
  }[];
  institution: Institution;
  patient: Patient;
  displayedRowCount?: number;
  patientTrackingDetails: (PatientTrackingDetails & { editing: boolean })[];
  activities: Activity[];
  billingId: string;
  physician?: Physician;
}

export interface Physician {
  id: string;
  name: string;
}

export interface PatientTrackingDetailsWithPatientMeta extends PatientTrackingDetails {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
}
export interface Patient {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  linkageId: string;
  workflows: PatientTrackingDetails[];
}

export interface GetPatientTrackerRecordsOpts {
  includeNonMatchingRecords?: boolean;
  includeAllCategories?: boolean;
  customFilterList?: PatientTrackerFilterField[];
}

export interface SyncedFields {
  consentDate: string | null;
  enrolledDate: string | null;
}
