import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { NO_LONGER_A_CANDIDATE_REASONS } from '@tempus/t-shared/src/constants/patient-tracker';
import React, { useEffect, useState } from 'react';
import { DropdownOption } from 'tcl-v3/models';

import InputFieldHelperText from '~/components/UpdateOverlay/DatePickerHelperText';
import { FieldStatus, getChangedValues } from '~/components/UpdateOverlay/utils';
import { TrialMatchUpdates } from '~/store/api/types';
import { parseEscapedCharacters } from '~/utils/misc';

import useStyles from './styles';

interface NoLongerACandidateProps {
  reasonNotAMatch: string;
  reasonNotAMatchDetails: string;
  handleChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const NoLongerACandidate: React.FC<NoLongerACandidateProps> = ({
  reasonNotAMatch,
  reasonNotAMatchDetails,
  handleChanges,
  setAreRequiredFieldsMissing,
}) => {
  const classes = useStyles();
  const [selectedReasonNotAMatch, setSelectedReasonNotAMatch] = useState<DropdownOption | null>(
    reasonNotAMatch ? { value: reasonNotAMatch, label: reasonNotAMatch } : null,
  );

  const [reasonNotAMatchDetailsText, setReasonNotAMatchDetailsText] = useState<string>(
    parseEscapedCharacters(reasonNotAMatchDetails || ''),
  );

  const getInitialValues = () => {
    return {
      reasonNotAMatch,
      reasonNotAMatchDetails: parseEscapedCharacters(reasonNotAMatchDetails),
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      reasonNotAMatch: selectedReasonNotAMatch?.value || null,
      reasonNotAMatchDetails: reasonNotAMatchDetailsText,
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleChanges(changedValues);
    setAreRequiredFieldsMissing(!selectedReasonNotAMatch);
  }, [selectedReasonNotAMatch, reasonNotAMatchDetailsText]);

  return (
    <>
      <div>
        <SingleSelectDropdown
          onChange={(reason) => setSelectedReasonNotAMatch(reason)}
          value={selectedReasonNotAMatch}
          label="Reason"
          options={Object.values(NO_LONGER_A_CANDIDATE_REASONS)
            .sort()
            .map((value) => ({ label: value, value }))}
          escapeClippingArea
          status={!selectedReasonNotAMatch ? 'error' : 'default'}
        />
        <InputFieldHelperText
          status={!selectedReasonNotAMatch ? FieldStatus.ERROR : FieldStatus.DEFAULT}
          message={'Reason is required.'}
        />
      </div>

      <TextArea
        label="Additional details"
        textAreaType="primary"
        className={classes.textArea}
        onChange={setReasonNotAMatchDetailsText}
        value={reasonNotAMatchDetailsText}
      />
    </>
  );
};

export default NoLongerACandidate;
