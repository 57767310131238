import { Card } from '@tcl-boron-prefabs/card';
import { Collapse } from '@tcl-boron-prefabs/collapse';
import { SingleDatePicker } from '@tcl-boron-prefabs/single-date-picker';
import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import { Toggle } from '@tcl-boron-prefabs/toggle';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import cn from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DropdownOption } from 'tcl-v3/models';

import { TrialMatchUpdates } from '~/store/api/types';
import { PatientTrackingDetails, SyncedFields } from '~/store/patientTrackerCommons/types';
import { formatDateForDisplay, parseEscapedCharacters } from '~/utils/misc';

import { getChangedValues, getFormattedDateForPicker } from '../utils';
import useStyles, { useCollapseStyles } from './styles';

const getResetOpts = (status: PatientCohortMatchWorkflowState) => {
  const resetOpts = [
    { label: 'Reset status to Internal review', value: PatientCohortMatchWorkflowState.INTERNAL_REVIEW },
  ];

  if (
    status !== PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW &&
    status !== PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW
  ) {
    resetOpts.unshift({
      label: 'Reset status to Pending site review',
      value: PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW,
    });
  }

  return resetOpts;
};

interface InternalControlsProps {
  ptd: PatientTrackingDetails;
  syncedFields: SyncedFields;
  setSyncedFields: (syncedFields: SyncedFields) => void;
  handleTrialMatchChanges: (changes: Partial<TrialMatchUpdates>) => void;
  newStatus: PatientCohortMatchWorkflowState | null;
  setNewStatus: (newStatus: PatientCohortMatchWorkflowState | null) => void;
}

export const InternalControls: React.FC<InternalControlsProps> = ({
  ptd,
  syncedFields,
  setSyncedFields,
  newStatus,
  setNewStatus,
  handleTrialMatchChanges,
}) => {
  const classes = useStyles();
  const collapseStyles = useCollapseStyles();

  const [isInitialMatchContentOpen, setIsInitialMatchContentOpen] = useState(
    [PatientCohortMatchWorkflowState.INTERNAL_REVIEW, PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW].includes(
      ptd.status as PatientCohortMatchWorkflowState,
    ),
  );

  const [isMatchFlagsOpen, setIsMatchFlagsOpen] = useState(false);
  const [isTAppScreeningOpen, setIsTAppScreeningOpen] = useState(false);
  const [isManagementOpen, setIsManagementOpen] = useState(false);
  const [urgent, setUrgent] = useState(Boolean(ptd.urgentFlag));
  const [screeningNote, setScreeningNote] = useState<string>(
    parseEscapedCharacters(ptd.tempusToPortalLatestPortalPatientUpdate || ''),
  );
  const [biomarkers, setBiomarkers] = useState<string>(parseEscapedCharacters(ptd.biomarkers || ''));
  const [readyForNotificationDate, setReadyForNotificationDate] = useState(ptd.dateRnClearedForNotification);
  const [notificationSentDate, setNotificationSentDate] = useState(ptd.dateNotificationSentToSite);
  const [rescreen, setRescreen] = useState(Boolean(ptd.rescreenFlag));
  const screeningNurse = ptd.screenedBy;
  const screeningDate = formatDateForDisplay(ptd.timeRnReviewCompletedDate, true);
  const tAppNotes = ptd.tAppNotes;

  const [internalRnToScreeningRnNote, setInternalRnToScreeningRnNote] = useState<string>(
    parseEscapedCharacters(ptd.internalRnToScreeningRnNote || ''),
  );

  const [noCredit, setNoCredit] = useState(Boolean(ptd.noCredit));
  const [resetOpt, setResetOpt] = useState<DropdownOption | null>(null);
  const consentDate = syncedFields.consentDate;
  const enrolledDate = syncedFields.enrolledDate;

  const setConsentDate = (date: string | null) => {
    setSyncedFields({ ...syncedFields, consentDate: date });
  };

  const setEnrolledDate = (date: string | null) => {
    setSyncedFields({ ...syncedFields, enrolledDate: date });
  };

  useEffect(() => {
    if (resetOpt?.value === PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW) {
      setNewStatus(PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW);
    } else if (resetOpt?.value === PatientCohortMatchWorkflowState.INTERNAL_REVIEW) {
      setNewStatus(PatientCohortMatchWorkflowState.INTERNAL_REVIEW);
    } else {
      setNewStatus(null);
    }
  }, [resetOpt]);

  useEffect(() => {
    if (!newStatus) {
      setResetOpt(null);
    }
  }, [newStatus]);

  const getInitialValues = () => {
    return {
      urgentFlag: ptd.urgentFlag,
      tempusToPortalLatestPortalPatientUpdate: ptd.tempusToPortalLatestPortalPatientUpdate
        ? parseEscapedCharacters(ptd.tempusToPortalLatestPortalPatientUpdate)
        : null,
      biomarkers: ptd.biomarkers ? parseEscapedCharacters(ptd.biomarkers) : null,
      dateRnClearedForNotification: ptd.dateRnClearedForNotification
        ? new Date(ptd.dateRnClearedForNotification).toISOString()
        : null,
      dateNotificationSentToSite: ptd.dateNotificationSentToSite
        ? new Date(ptd.dateNotificationSentToSite).toISOString()
        : null,
      rescreenFlag: ptd.rescreenFlag || false,
      internalRnToScreeningRnNote: ptd.internalRnToScreeningRnNote
        ? parseEscapedCharacters(ptd.internalRnToScreeningRnNote)
        : null,
      noCredit: ptd.noCredit,
      patientConsentedDate: ptd.patientConsentedDate ? new Date(ptd.patientConsentedDate).toISOString() : null,
      firstTreatmentDate: ptd.firstTreatmentDate ? new Date(ptd.firstTreatmentDate).toISOString() : null,
    };
  };

  useEffect(() => {
    const currentValues: Partial<TrialMatchUpdates> = {
      urgentFlag: urgent,
      tempusToPortalLatestPortalPatientUpdate: screeningNote || null,
      biomarkers: biomarkers || null,
      dateRnClearedForNotification: readyForNotificationDate ? new Date(readyForNotificationDate).toISOString() : null,
      dateNotificationSentToSite: notificationSentDate ? new Date(notificationSentDate).toISOString() : null,
      rescreenFlag: rescreen,
      internalRnToScreeningRnNote: internalRnToScreeningRnNote || null,
      noCredit,
      patientConsentedDate: consentDate ? new Date(consentDate).toISOString() : null,
      firstTreatmentDate: enrolledDate ? new Date(enrolledDate).toISOString() : null,
    };

    const changedValues: Partial<TrialMatchUpdates> = getChangedValues(getInitialValues(), currentValues);
    handleTrialMatchChanges(changedValues);
  }, [
    urgent,
    screeningNote,
    biomarkers,
    readyForNotificationDate,
    notificationSentDate,
    rescreen,
    internalRnToScreeningRnNote,
    noCredit,
    consentDate,
    enrolledDate,
  ]);

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.header}>Tempus Internal Controls</div>
        <div className={classes.collapseContainer}>
          <Collapse
            title="Initial match content"
            titlePlacement="right"
            isOpen={isInitialMatchContentOpen}
            onChange={() => setIsInitialMatchContentOpen(!isInitialMatchContentOpen)}
            classes={collapseStyles}>
            <>
              <div className={classes.matchFlags}>
                <Toggle
                  label="Urgent"
                  isSelected={urgent}
                  labelPlacement="left"
                  onChange={() => setUrgent(!urgent)}
                  hideCheckMark
                  className={classes.borderedToggle}
                />
              </div>
              <TextArea
                textAreaType="secondary"
                label="Screening note"
                value={screeningNote}
                onChange={setScreeningNote}
                className={classes.textArea}
              />
              <TextArea
                textAreaType="secondary"
                label="Matching biomarkers"
                value={biomarkers}
                onChange={setBiomarkers}
                className={cn(classes.textArea, classes.mutations)}
              />
              <div className={classes.twoColumns}>
                <SingleDatePicker
                  inputType="secondary"
                  label="Ready for notification"
                  onChange={(date) => setReadyForNotificationDate(getFormattedDateForPicker(date.dateString))}
                  value={{ dateString: moment.utc(readyForNotificationDate).format('MM/DD/YYYY') }}
                />
                <SingleDatePicker
                  inputType="secondary"
                  label="Notification sent"
                  onChange={(date) => setNotificationSentDate(getFormattedDateForPicker(date.dateString))}
                  value={{ dateString: moment.utc(notificationSentDate).format('MM/DD/YYYY') }}
                />
              </div>
            </>
          </Collapse>
        </div>
        <div className={classes.collapseContainer}>
          <Collapse
            title="Match flags"
            titlePlacement="right"
            isOpen={isMatchFlagsOpen}
            onChange={() => setIsMatchFlagsOpen(!isMatchFlagsOpen)}
            classes={collapseStyles}>
            <div className={classes.matchFlags}>
              <Toggle
                label="Rescreen"
                isSelected={rescreen}
                labelPlacement="left"
                onChange={() => setRescreen(!rescreen)}
                hideCheckMark
                className={classes.borderedToggle}
              />
            </div>
          </Collapse>
        </div>
        <div className={classes.collapseContainer}>
          <Collapse
            title="T-App screening"
            titlePlacement="right"
            isOpen={isTAppScreeningOpen}
            onChange={() => setIsTAppScreeningOpen(!isTAppScreeningOpen)}
            classes={collapseStyles}>
            <>
              <div className={classes.twoColumns}>
                <div>
                  <div className={classes.label}>Screening nurse</div>
                  <div>{screeningNurse || 'Screening nurse unknown'}</div>
                </div>
                <div>
                  <div className={classes.label}>Screening date</div>
                  <div>{screeningDate || 'Screening date unknown'}</div>
                </div>
              </div>
              <div className={classes.noteRow}>
                <div className={classes.label}>T-app screening note</div>
                <div>{tAppNotes ? parseEscapedCharacters(tAppNotes) : 'No notes provided'}</div>
              </div>
              <TextArea
                textAreaType="secondary"
                label="Internal RN to Screening RN note"
                value={internalRnToScreeningRnNote}
                onChange={setInternalRnToScreeningRnNote}
                className={cn(classes.textArea, classes.internalNote)}
              />
            </>
          </Collapse>
        </div>
        <div className={classes.collapseContainer}>
          <Collapse
            title="Management"
            titlePlacement="right"
            isOpen={isManagementOpen}
            onChange={() => setIsManagementOpen(!isManagementOpen)}
            classes={collapseStyles}>
            <>
              {ptd.status !== PatientCohortMatchWorkflowState.INTERNAL_REVIEW && (
                <SingleSelectDropdown
                  onChange={(opt) => setResetOpt(opt)}
                  value={resetOpt}
                  label="Reset match"
                  options={getResetOpts(ptd.status as PatientCohortMatchWorkflowState)}
                  escapeClippingArea
                  clearable
                />
              )}
              <div className={classes.divider} />
              <div className={classes.matchFlags}>
                <Toggle
                  label="No credit"
                  isSelected={noCredit}
                  labelPlacement="left"
                  onChange={() => setNoCredit(!noCredit)}
                  hideCheckMark
                  className={classes.borderedToggle}
                />
              </div>
              <div className={classes.divider} />
              <div className={classes.twoColumns}>
                <SingleDatePicker
                  inputType="primary"
                  label="Edit consent date"
                  onChange={(date) => setConsentDate(getFormattedDateForPicker(date.dateString))}
                  value={{ dateString: moment.utc(consentDate).format('MM/DD/YYYY') }}
                />
                <SingleDatePicker
                  inputType="primary"
                  label="Edit enrolled date"
                  onChange={(date) => setEnrolledDate(getFormattedDateForPicker(date.dateString))}
                  value={{ dateString: moment.utc(enrolledDate).format('MM/DD/YYYY') }}
                />
              </div>
            </>
          </Collapse>
        </div>
      </>
    </Card>
  );
};

export default InternalControls;
