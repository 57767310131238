import { ArrowLeft, Checkmark, NotEditable, Pending } from '@tcl-boron-icons/icons';
import { Button } from '@tcl-boron-prefabs/button';
import { Card } from '@tcl-boron-prefabs/card';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import { ROLES } from '@tempus/t-shared';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import MatchStatus from '~/components/PatientTrackerCard/MatchStatus';
import { RootState } from '~/store';
import { TrialMatchUpdates } from '~/store/api/types';
import { getPatientCohortMatchWorkflowStateToDisplay } from '~/store/patientTrackerCommons/constants';
import { PatientTrackingDetails, SyncedFields, TimePatient } from '~/store/patientTrackerCommons/types';

import { getSnoozeDataFromPtd } from '../utils';
import CustomTooltip from './CustomTooltip';
import Consented from './MatchStatusFields/Consented';
import Enrolled from './MatchStatusFields/Enrolled';
import ImminentMatchPursuingActivation from './MatchStatusFields/ImminentMatchPursuingActivation';
import NoLongerACandidate from './MatchStatusFields/NoLongerACandidate';
import Watchlist from './MatchStatusFields/Watchlist';
import useStyles from './styles';

const allowedNextStatuses = {
  [PatientCohortMatchWorkflowState.INTERNAL_REVIEW]: [PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW],
  [PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW]: [
    PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW,
    PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
  ],
  [PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW]: [
    'Candidate now',
    PatientCohortMatchWorkflowState.WATCHLIST,
    PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
  ],
  [PatientCohortMatchWorkflowState.WATCHLIST]: ['Candidate now', PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE],
  'Candidate now': [
    PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE,
    PatientCohortMatchWorkflowState.PURSUING_ACTIVATION,
    PatientCohortMatchWorkflowState.CONSENTED,
  ],
  [PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE]: [
    PatientCohortMatchWorkflowState.CONSENTED,
    PatientCohortMatchWorkflowState.PURSUING_ACTIVATION,
    PatientCohortMatchWorkflowState.WATCHLIST,
    PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
  ],
  [PatientCohortMatchWorkflowState.PURSUING_ACTIVATION]: [
    PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE,
    PatientCohortMatchWorkflowState.CONSENTED,
    PatientCohortMatchWorkflowState.WATCHLIST,
    PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
  ],
  [PatientCohortMatchWorkflowState.CONSENTED]: [
    PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE,
    PatientCohortMatchWorkflowState.PURSUING_ACTIVATION,
    PatientCohortMatchWorkflowState.ENROLLED,
    PatientCohortMatchWorkflowState.WATCHLIST,
    PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE,
  ],
  [PatientCohortMatchWorkflowState.ENROLLED]: [],
  [PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE]: ['Candidate now', PatientCohortMatchWorkflowState.WATCHLIST],
};

const statusIcon = {
  [PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW]: <Checkmark />,
  'Candidate now': <Checkmark />,
  [PatientCohortMatchWorkflowState.WATCHLIST]: <Pending />,
  [PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE]: null,
  [PatientCohortMatchWorkflowState.PURSUING_ACTIVATION]: null,
  [PatientCohortMatchWorkflowState.CONSENTED]: <Checkmark />,
  [PatientCohortMatchWorkflowState.ENROLLED]: <Checkmark />,
  [PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE]: <NotEditable />,
  Back: <ArrowLeft />,
};

const statusTooltip = {
  'Candidate now': 'Patient is a good fit now',
  [PatientCohortMatchWorkflowState.WATCHLIST]: 'Watchlist match for review again later',
  [PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE]: 'Patient is a good fit now',
  [PatientCohortMatchWorkflowState.PURSUING_ACTIVATION]: 'Trial is being activated for this patient',
  [PatientCohortMatchWorkflowState.CONSENTED]: 'Patient has consented to this trial',
  [PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE]: 'Patient is not a match for this trial',
};

interface MatchStatusUpdateProps {
  ptd: PatientTrackingDetails;
  timePatientVisit?: TimePatient['timePatientVisits'][0];
  newStatus: PatientCohortMatchWorkflowState | null;
  setNewStatus: (status: PatientCohortMatchWorkflowState | null) => void;
  syncedFields: SyncedFields;
  setSyncedFields: (syncedFields: SyncedFields) => void;
  handleTrialMatchChanges: (changes: Partial<TrialMatchUpdates>) => void;
  setAreRequiredFieldsMissing: (areRequiredFieldsMissing: boolean) => void;
}

export const MatchStatusUpdate: React.FC<MatchStatusUpdateProps> = ({
  ptd,
  timePatientVisit,
  newStatus,
  setNewStatus,
  syncedFields,
  setSyncedFields,
  handleTrialMatchChanges,
  setAreRequiredFieldsMissing,
}) => {
  const classes = useStyles();

  const isInternalUser = useSelector((state: RootState) =>
    state.user.effectiveRoles.includes(ROLES.T_PATIENT_TRACKER_READ_INTERNAL),
  );

  const [showCandidateNowStatuses, setShowCandidateNowStatuses] = useState(false);
  const [showMatchStatusFields, setShowMatchStatusFields] = useState(false);

  const matchStatusesWithoutFields = [
    PatientCohortMatchWorkflowState.INTERNAL_REVIEW,
    PatientCohortMatchWorkflowState.PENDING_TEMPUS_REVIEW,
    PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW,
  ];

  useEffect(() => {
    if (
      (!newStatus && !matchStatusesWithoutFields.includes(ptd.status as PatientCohortMatchWorkflowState)) ||
      (newStatus && !matchStatusesWithoutFields.includes(newStatus))
    ) {
      setShowMatchStatusFields(true);
    } else {
      setShowMatchStatusFields(false);
    }
  }, [ptd.status, newStatus]);

  const newStatusButtonClicked = (status: PatientCohortMatchWorkflowState | 'Candidate now') => {
    if (status === 'Candidate now') {
      setNewStatus(null);
      setShowCandidateNowStatuses(true);
    } else {
      setNewStatus(status);
      setShowCandidateNowStatuses(false);
    }
  };

  const backToOldStatus = () => {
    setNewStatus(null);
    setShowCandidateNowStatuses(false);
  };

  const renderMatchStatusRelatedFields = () => {
    switch (newStatus || ptd.status) {
      case PatientCohortMatchWorkflowState.WATCHLIST:
        return (
          <Watchlist
            snoozeData={getSnoozeDataFromPtd(ptd)}
            nextVisitType={timePatientVisit?.visitType || ''}
            nextVisitDate={timePatientVisit?.visitDate || ''}
            handleChanges={handleTrialMatchChanges}
            setAreRequiredFieldsMissing={setAreRequiredFieldsMissing}
          />
        );

      case PatientCohortMatchWorkflowState.IMMINENT_CANDIDATE:
      case PatientCohortMatchWorkflowState.PURSUING_ACTIVATION:
        return (
          <ImminentMatchPursuingActivation
            nextVisitType={timePatientVisit?.visitType || ''}
            nextVisitDate={timePatientVisit?.visitDate || ''}
            handleChanges={handleTrialMatchChanges}
            setAreRequiredFieldsMissing={setAreRequiredFieldsMissing}
          />
        );

      case PatientCohortMatchWorkflowState.CONSENTED:
        return (
          <Consented
            consentDate={ptd.patientConsentedDate || ''}
            consentRecognizedDate={ptd.dateConsentRecognizedByTempus || ''}
            syncedFields={syncedFields}
            setSyncedFields={setSyncedFields}
            nextVisitType={timePatientVisit?.visitType || ''}
            nextVisitDate={timePatientVisit?.visitDate || ''}
            handleChanges={handleTrialMatchChanges}
            setAreRequiredFieldsMissing={setAreRequiredFieldsMissing}
          />
        );

      case PatientCohortMatchWorkflowState.ENROLLED:
        return (
          <Enrolled
            enrolledDate={ptd.firstTreatmentDate || ''}
            syncedFields={syncedFields}
            setSyncedFields={setSyncedFields}
            handleChanges={handleTrialMatchChanges}
            setAreRequiredFieldsMissing={setAreRequiredFieldsMissing}
          />
        );

      case PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE:
        return (
          <NoLongerACandidate
            reasonNotAMatch={ptd.reasonNotAMatch || ''}
            reasonNotAMatchDetails={ptd.reasonNotAMatchDetails || ''}
            handleChanges={handleTrialMatchChanges}
            setAreRequiredFieldsMissing={setAreRequiredFieldsMissing}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <Card className={classes.trialMatchCard}>
      <>
        <div className={classes.headerRow}>
          <div className={classes.header}>Match status</div>
          {newStatus && (
            <Button
              buttonType="secondary"
              small
              onClick={() => backToOldStatus()}
              ariaLabel="Move back to original status">
              <div className={classes.newStatusButtons}>
                {statusIcon['Back']}
                Back
              </div>
            </Button>
          )}
        </div>
        <MatchStatus status={newStatus || ptd.status} />
        {showMatchStatusFields && (
          <Card className={cn(classes.matchDetailsCard, classes.matchStatusCards)}>
            {renderMatchStatusRelatedFields()}
          </Card>
        )}
        {Boolean(!newStatus && allowedNextStatuses[ptd.status].length) && (
          <Card className={cn(classes.matchDetailsCard, classes.matchStatusCards)}>
            <>
              <div className={classes.detailsHeader}>Change status</div>
              <div className={classes.newStatusButtons}>
                {allowedNextStatuses[ptd.status].map((status) => (
                  <div key={status}>
                    {Boolean(status !== PatientCohortMatchWorkflowState.PENDING_SITE_REVIEW || isInternalUser) && (
                      <CustomTooltip message={statusTooltip[status]} position="bottom">
                        <Button
                          key={status}
                          buttonType="secondary"
                          small
                          className={
                            showCandidateNowStatuses && status === 'Candidate now' ? classes.candidateNowButton : ''
                          }
                          onClick={() => newStatusButtonClicked(status)}
                          ariaLabel={`Update status to ${status}`}>
                          <div className={classes.newStatusButtons}>
                            {statusIcon[status]}
                            {getPatientCohortMatchWorkflowStateToDisplay(status)}
                          </div>
                        </Button>
                      </CustomTooltip>
                    )}
                  </div>
                ))}
              </div>
              {showCandidateNowStatuses && (
                <div className={classes.candidateNowStatuses}>
                  <div className={cn(classes.detailsHeader, classes.candidateNowStatusHeader)}>Candidate status</div>
                  {allowedNextStatuses['Candidate now'].map((status) => (
                    <CustomTooltip key={status} message={statusTooltip[status]} position="right">
                      <Button
                        key={status}
                        buttonType="secondary"
                        small
                        onClick={() => newStatusButtonClicked(status)}
                        ariaLabel={`Update status to ${status}`}>
                        <div className={classes.newStatusButtons}>
                          {statusIcon[status]}
                          {getPatientCohortMatchWorkflowStateToDisplay(status)}
                        </div>
                      </Button>
                    </CustomTooltip>
                  ))}
                </div>
              )}
            </>
          </Card>
        )}
      </>
    </Card>
  );
};

export default MatchStatusUpdate;
