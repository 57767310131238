import { Button } from '@tcl-boron-prefabs/button';
import { Modal } from '@tcl-boron-prefabs/modal';
import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { ALL_SITES } from '@tempus/t-shared';
import { AddPatientStatus, PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import { storeActions } from '@tempus/t-shared/ui';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from '~/store';
import api from '~/store/api';
import {
  CreatePatientAndTrackingRecord,
  PatientFromStateflow,
  PatientTrackingRecordCreateRequest,
  TimePatientCreateRequest,
} from '~/store/api/types';
import { getPatientTrackerRoute } from '~/store/patientTrackerCommons/helpers';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { creators as patientTrackerV2Creators } from '~/store/patientTrackerV2';
import { creators as patientTrackerV3Creators } from '~/store/patientTrackerV3';
import { formatDateToYearMonthDay } from '~/utils/misc';

import DuplicateMatch from './DuplicateMatch';
import IneligibleMatch from './IneligibleMatch';
import PatientIdentification from './PatientIdentification';
import PatientMatchInformation from './PatientMatchInformation';
import useStyles, { useBaseModalStyles } from './styles';
import { PatientInputFieldsEnum, PatientInputFields, PatientInputFieldsUpdates, AddPatientPage } from './types';
import { getEnrollingCohorts, getProgramFromCohorts } from './utils';

const initPatientInputFields: PatientInputFields = {
  [PatientInputFieldsEnum.FIRST_NAME]: null,
  [PatientInputFieldsEnum.LAST_NAME]: null,
  [PatientInputFieldsEnum.DOB]: '',
  [PatientInputFieldsEnum.NCT_ID]: '',
  [PatientInputFieldsEnum.TRIAL_ID]: '',
  [PatientInputFieldsEnum.COHORT]: [],
  [PatientInputFieldsEnum.BIOMARKER]: '',
  [PatientInputFieldsEnum.COMMENTS]: '',
  [PatientInputFieldsEnum.NEXT_VISIT_DATE]: '',
  [PatientInputFieldsEnum.VISIT_TYPE]: '',
  [PatientInputFieldsEnum.MRN]: '',
};

interface AddPatientProps {
  storeKeys: StoreKeys;
  hideButton?: boolean;
  setIsAddPatientModalOpen?: (arg1: boolean) => void;
  prepopulatedPatientValues?: Partial<PatientInputFields>;
}

const AddPatient: React.FC<AddPatientProps> = ({
  storeKeys: { store, stateMapKey: stateId },
  hideButton = false,
  setIsAddPatientModalOpen = noop,
  prepopulatedPatientValues = {},
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const patientTrackerCreators = store === 'patientTrackerV2' ? patientTrackerV2Creators : patientTrackerV3Creators;
  const [isModalOpen, setIsModalOpen] = useState(hideButton);
  const [isPersisting, setPersisting] = useState(false);
  const [currentPage, setCurrentPage] = useState(AddPatientPage.IDENTIFICATION_PAGE);
  const [selectedPatientLinkageId, setSelectedPatientLinkageId] = useState<string | undefined>(undefined);
  const [patientInputFields, setPatientInputFields] = useState<PatientInputFields>({
    ...initPatientInputFields,
    ...prepopulatedPatientValues,
  });
  const [duplicateMatchRecord, setDuplicateMatchRecord] = useState<PatientFromStateflow | null>(null);
  const userSites = useSelector(({ site }: RootState) => site.userSites);
  const effectiveUserSites = userSites || {};
  const selectedUserSite = useSelector(({ site }: RootState) => site.selectedUserSite);
  const trials = useSelector(({ trial }: RootState) => trial.allTrials);
  const currentSiteId = selectedUserSite?.id || Object.keys(effectiveUserSites)[0];

  const modalStyle =
    currentPage === AddPatientPage.IDENTIFICATION_PAGE
      ? classes.identificationModal
      : currentPage === AddPatientPage.MATCH_INFORMATION_PAGE
      ? classes.matchInformationModal
      : classes.duplicateMatchModal;

  const updatePatientInputFields = (updates: PatientInputFieldsUpdates) => {
    setPatientInputFields({ ...patientInputFields, ...updates });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAddPatientModalOpen(false);
    setPatientInputFields(initPatientInputFields);
    setCurrentPage(AddPatientPage.IDENTIFICATION_PAGE);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const createPatientTrackerBody = (cohorts, trialId) => {
    const trackingRecordBody: PatientTrackingRecordCreateRequest = {
      nctId: patientInputFields.NCT_ID,
      cohortId: patientInputFields.COHORT.map((o) => o.value).join(','),
      program: getProgramFromCohorts(cohorts),
      biomarker: patientInputFields.BIOMARKER,
      status: PATIENT_TRACKER_STATUSES.PENDING_TEMPUS_REVIEW,
      note: patientInputFields.COMMENTS,
      trialId,
    };
    if (patientInputFields.NEXT_VISIT_DATE) {
      trackingRecordBody.nextVisitDate = formatDateToYearMonthDay(patientInputFields.NEXT_VISIT_DATE);
    }
    if (patientInputFields.VISIT_TYPE) {
      trackingRecordBody.visitType = patientInputFields.VISIT_TYPE;
    }

    return trackingRecordBody;
  };

  const createPatientBody = (cohorts) => {
    const timePatientBody: TimePatientCreateRequest = {
      patientFirstName: patientInputFields.FIRST_NAME as string,
      patientLastName: patientInputFields.LAST_NAME as string,
      patientDob: formatDateToYearMonthDay(patientInputFields.DOB as string),
      program: getProgramFromCohorts(cohorts),
    };

    return timePatientBody;
  };

  const performAddPatient = async () => {
    try {
      setPersisting(true);

      const patientFullname = `${patientInputFields.FIRST_NAME} ${patientInputFields.LAST_NAME}`;
      const trial = trials.find((t) => t.nctId === patientInputFields.NCT_ID);
      const cohorts = getEnrollingCohorts(trial);

      if (patientInputFields.MRN && patientInputFields.FIRST_NAME && patientInputFields.LAST_NAME) {
        const patientTrackerRecordBody = createPatientTrackerBody(cohorts, trial?.id);
        const body: CreatePatientAndTrackingRecord = {
          mrn: patientInputFields.MRN,
          patientTracker: patientTrackerRecordBody,
        };

        // if the user selected an existing patient from the dropdown
        if (selectedPatientLinkageId) {
          body.patientLinkageId = selectedPatientLinkageId;
        } else {
          body.patient = createPatientBody(cohorts);
        }

        const { status } = await api.patientTrackerV2.createPatientAndTrackingRecord(currentSiteId as string, body);
        if (status === AddPatientStatus.DUPLICATE_MATCH) {
          setCurrentPage(AddPatientPage.DUPLICATE_MATCH_PAGE);
          return;
        } else if (status === AddPatientStatus.INELIGIBLE_MATCH) {
          setCurrentPage(AddPatientPage.INELIGIBLE_MATCH_PAGE);
          return;
        }

        const message = `You added ${patientFullname}. Your Tempus site representative will be notified.`;
        dispatch(storeActions.notification.showSuccessMessage(message));

        dispatch(patientTrackerCreators.getPatientTrackerRecords(stateId, currentSiteId as string));
        dispatch(patientTrackerCreators.getPatientTrackerCategoryCounts(stateId, currentSiteId as string));
      }

      closeModal();
    } catch (err) {
      dispatch(
        patientTrackerCreators.showPatientTrackerErrorMessage(stateId, err, 'Failed to add patient. Please try again.'),
      );
    } finally {
      setPersisting(false);
    }
  };

  const viewDuplicateMatch = () => {
    if (duplicateMatchRecord?.matches[0].status) {
      // TODO: update behavior
      // dispatch(
      //   patientTrackerCreators.setPatientTrackerTab(
      //     stateId,
      //     PATIENT_TRACKER_CATEGORY_LOOKUP[duplicateMatchRecord.patientTrackingDetails[0].status],
      //   ),
      // );
    }

    const route = getPatientTrackerRoute(stateId);

    history.push(route.path.replace(':siteId?', currentSiteId || ALL_SITES));
    closeModal();
  };

  return (
    <React.Fragment>
      <Button
        hidden={hideButton}
        onClick={openModal}
        buttonType="secondary"
        data-testid="add-patient-button"
        ariaLabel="Add Patient"
        autoFocus={false}>
        Add Patient
      </Button>
      <Modal
        classes={useBaseModalStyles()}
        className={modalStyle}
        style={{
          overlay: {
            overflow: 'scroll',
            zIndex: 5,
          },
        }}
        title=""
        size="small"
        isOpen={isModalOpen}
        onRequestClose={closeModal}>
        {![AddPatientPage.DUPLICATE_MATCH_PAGE, AddPatientPage.INELIGIBLE_MATCH_PAGE].includes(currentPage) && (
          <div className={typography.sectionHeader}>
            <strong>Add patient</strong>
          </div>
        )}
        {currentPage === AddPatientPage.IDENTIFICATION_PAGE && currentSiteId && (
          <PatientIdentification
            siteId={currentSiteId}
            patientInputFields={patientInputFields}
            updatePatientInputFields={updatePatientInputFields}
            closeModal={closeModal}
            navigateToPage={setCurrentPage}
            setSelectedPatientLinkageId={setSelectedPatientLinkageId}
            setDuplicateMatchRecord={setDuplicateMatchRecord}
          />
        )}
        {currentPage === AddPatientPage.MATCH_INFORMATION_PAGE && (
          <PatientMatchInformation
            patientInputFields={patientInputFields}
            updatePatientInputFields={updatePatientInputFields}
            navigateToPage={setCurrentPage}
            performAddPatient={performAddPatient}
            isPersisting={isPersisting}
          />
        )}
        {currentPage === AddPatientPage.DUPLICATE_MATCH_PAGE && (
          <DuplicateMatch viewDuplicateMatch={viewDuplicateMatch} navigateToPage={setCurrentPage} />
        )}
        {currentPage === AddPatientPage.INELIGIBLE_MATCH_PAGE && <IneligibleMatch navigateToPage={setCurrentPage} />}
      </Modal>
    </React.Fragment>
  );
};

export default AddPatient;
