import { GrayscalePalette } from '@tcl-boron-colors/colors';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  trialMatchCard: {
    margin: '16px 0',
    height: 'auto',
    padding: '16px 0px',
    fontSize: '14px',
    lineHeight: '20px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.1)',

    '& > div > div': {
      overflowY: 'hidden',
      padding: '0 0 4px 0',
    },
  },
  trialDetailsSection: {
    padding: '0 16px',
  },
  cardHeader: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: 'black',
  },
  detailsHeader: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  twoColumns: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
    alignItems: 'center',
  },
  matchStatusFlags: {
    display: 'flex',
    gap: '16px',
    marginBottom: '16px',
    alignItems: 'center',
  },
  matchDetailsCard: {
    backgroundColor: GrayscalePalette[200],
    padding: '16px',
    boxShadow: 'none',

    '& > div > div': {
      overflowY: 'hidden',
    },
  },
  trialMaterials: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontWeight: 600,
    color: 'black',
    cursor: 'pointer',
    marginBottom: '16px',
  },
  hiddenDetails: {
    marginBottom: 0,
  },
  oneLineStatus: {
    width: '378px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  matchDetailsGrid: {
    display: 'grid',
    gridTemplateColumns: '200px auto',
    rowGap: '16px',
    columnGap: '20px',
    textAlign: 'left',
    marginBottom: '20px',
  },
  matchLabel: {
    fontWeight: 'normal',
    textAlign: 'left',
  },
  value: {
    textAlign: 'left',
  },
});
