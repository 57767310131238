import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { PatientCohortMatchWorkflowState } from '@tempus/stateflow-types';
import { Activity, MatchStatusUpdates } from '@tempus/t-shared';
import { PATIENT_TRACKER_STATUSES } from '@tempus/t-shared/src/constants/patient-tracker';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { getPatientCohortMatchWorkflowStateToDisplay } from '~/store/patientTrackerCommons/constants';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const MatchStatusUpdateActivity = ({
  storeKeys,
  activity,
  isInternalUser,
}: {
  storeKeys: StoreKeys;
  activity: Activity;
  isInternalUser: boolean;
}) => {
  const classes = useStyles();

  const ptds = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) =>
      patientTracker[storeKeys.stateMapKey].patient.data?.patientTrackingDetails,
  );

  let shouldDisplay = true;
  if ('newStatus' in activity.content) {
    const isInternalReview =
      activity.content.newStatus === PATIENT_TRACKER_STATUSES.INTERNAL_REVIEW ||
      activity.content.newStatus === PatientCohortMatchWorkflowState.INTERNAL_REVIEW;
    shouldDisplay = !isInternalReview ? true : isInternalReview && isInternalUser;
  }

  return (
    <>
      {shouldDisplay && (
        <li key={activity.id} className={cn(typography.body, classes.li)}>
          <div className={classes.listItemContainer}>
            <div className={classes.updatesLeft}>
              <div>
                Patient Updated to{' '}
                <em>
                  {getPatientCohortMatchWorkflowStateToDisplay(
                    (activity.content as MatchStatusUpdates).newStatus as PatientCohortMatchWorkflowState,
                  )}
                </em>{' '}
                for <strong>{getTrialDisplay((activity.content as MatchStatusUpdates).trialId, ptds || [])}</strong>
              </div>
              {((activity.content as MatchStatusUpdates).newStatus === PATIENT_TRACKER_STATUSES.NO_LONGER_A_CANDIDATE ||
                (activity.content as MatchStatusUpdates).newStatus ===
                  PatientCohortMatchWorkflowState.NO_LONGER_A_CANDIDATE) && (
                <>
                  <div>Reason: {(activity.content as MatchStatusUpdates).reasonNotAMatch}</div>
                  {(activity.content as MatchStatusUpdates).reasonNotAMatchDetails && (
                    <div>Additional details: {(activity.content as MatchStatusUpdates).reasonNotAMatchDetails}</div>
                  )}
                </>
              )}

              {(activity.content as MatchStatusUpdates).additionalDetails && (
                <div className={classes.additionalDetails}>
                  <div className={classes.noteBody}>
                    <div className={classes.noteText}>{(activity.content as MatchStatusUpdates).additionalDetails}</div>
                    <span className={cn(typography.supportingBody, typography.gray)}>- {activity.userName}</span>
                  </div>
                </div>
              )}
            </div>
            <div>{activity.createdAt}</div>
          </div>
        </li>
      )}
    </>
  );
};
