import typography from '@tcl-boron-styles/typography/dist/index.module.scss';
import { Activity, NewTrialMatch } from '@tempus/t-shared';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '~/store';
import { StoreKeys } from '~/store/patientTrackerCommons/types';
import { getTrialDisplay } from '~/utils/trial';

import useStyles from './ActivityStatus.styles';

export const NewTrialMatchActivity = ({ storeKeys, activity }: { storeKeys: StoreKeys; activity: Activity }) => {
  const classes = useStyles();

  const ptds = useSelector(
    ({ [storeKeys.store]: patientTracker }: RootState) =>
      patientTracker[storeKeys.stateMapKey].patient.data?.patientTrackingDetails,
  );

  return (
    <>
      <li key={activity.id} className={cn(typography.body, classes.li)}>
        <div className={classes.listItemContainer}>
          <div className={classes.updatesLeft}>
            Patient has a new match to{' '}
            <strong>{getTrialDisplay((activity.content as NewTrialMatch).trialId, ptds || [])}</strong>
          </div>
          <div>{activity.createdAt}</div>
        </div>
      </li>
    </>
  );
};
