import { PatientCohortMatchWorkflowState, SortBy } from '@tempus/stateflow-types';
import { getMappedProgramSiteStatuses, LfsTrialStatus, TrialType } from '@tempus/t-shared';
import { Filters, GetPatientsRequestBody, MatchFlag, Tab } from '@tempus/t-shared/src/constants/patient-tracker';

import { MATCH_STATUS_FLAGS, PATIENT_TRACKER_V3_TAB, SORT_OPTION } from '../patientTrackerCommons/constants';
import {
  PatientTrackerFilterField,
  PatientTrackerFilters,
  PatientTrackingDetails,
} from '../patientTrackerCommons/types';

const matchFlagForReq = {
  [MATCH_STATUS_FLAGS.COHORT_ON_HOLD]: MatchFlag.COHORT_ON_HOLD,
  [MATCH_STATUS_FLAGS.SITE_ON_HOLD]: MatchFlag.SITE_ON_HOLD,
  [MATCH_STATUS_FLAGS.NEW]: MatchFlag.NEW,
  [MATCH_STATUS_FLAGS.URGENT]: MatchFlag.URGENT,
  [MATCH_STATUS_FLAGS.RESCREEN]: MatchFlag.RESCREEN,
};

const tabForReq = {
  [PATIENT_TRACKER_V3_TAB.REVIEW_REQUIRED]: Tab.REVIEW_REQUIRED,
  [PATIENT_TRACKER_V3_TAB.WATCHLIST]: Tab.WATCHLIST,
  [PATIENT_TRACKER_V3_TAB.CANDIDATE_NOW]: Tab.CANDIDATE_NOW,
  [PATIENT_TRACKER_V3_TAB.SCREENING_QUEUE]: Tab.SCREENING_QUEUE,
  [PATIENT_TRACKER_V3_TAB.ENROLLED]: Tab.ENROLLED,
  [PATIENT_TRACKER_V3_TAB.VISITING_THIS_WEEK]: Tab.VISITING_THIS_WEEK,
  [PATIENT_TRACKER_V3_TAB.TIME_SENSITIVE]: Tab.TIME_SENSITIVE,
  [PATIENT_TRACKER_V3_TAB.ALL]: Tab.ALL,
};

const getSortByForReq = (option: SORT_OPTION): SortBy[] => {
  switch (option) {
    case SORT_OPTION.URGENCY:
      return [
        { field: 'urgency_sort_ordinal', order: 'ASC' },
        { field: 'urgency_sort_secondary_ordinal_date', order: 'ASC' },
      ];
    case SORT_OPTION.UPCOMING_VISIT_SOONEST_FIRST:
      return [{ table: 'time_patient_visits', field: 'visit_date', order: 'ASC' }];
    case SORT_OPTION.UPCOMING_VISIT_FURTHEST_FIRST:
      return [{ table: 'time_patient_visits', field: 'visit_date', order: 'DESC' }];
    case SORT_OPTION.OLDEST:
      return [{ table: 'workflow', field: 'created_at', order: 'ASC' }];
    case SORT_OPTION.NEWEST:
      return [{ table: 'workflow', field: 'created_at', order: 'DESC' }];
    default:
      return [
        { field: 'urgency_sort_ordinal', order: 'ASC' },
        { field: 'urgency_sort_secondary_ordinal_date', order: 'ASC' },
      ];
  }
};

export const getFiltersForReq = (
  institutionId: string,
  filters: Partial<PatientTrackerFilters>,
  tab?: PATIENT_TRACKER_V3_TAB,
  customFilterList?: PatientTrackerFilterField[],
) => {
  let sortBy: GetPatientsRequestBody['sortBy'] = [];

  let filtersForReq: Filters = {
    ...(tab && { tabName: tabForReq[tab] }),
    institutionId,
  };

  Object.keys(filters).forEach((key) => {
    if (customFilterList && !customFilterList.includes(key as PatientTrackerFilterField)) {
      return;
    }

    switch (key) {
      case PatientTrackerFilterField.RN_ASSIGNMENT:
        filtersForReq = { ...filtersForReq, assignedNurseName: filters.rnAssignment };
        break;

      case PatientTrackerFilterField.TRIAL_NAME:
        filtersForReq = { ...filtersForReq, trialId: filters.trialName?.value };
        break;

      case PatientTrackerFilterField.MATCH_STATUS:
        const matchFlags = filters.matchStatus?.filter((opt) => Boolean(opt.subContent));
        const matchStatuses = filters.matchStatus?.filter((opt) => !Boolean(opt.subContent));

        filtersForReq = {
          ...(matchFlags?.length ? { matchFlags: matchFlags.map((opt) => matchFlagForReq[opt.value]) } : {}),
          ...(matchStatuses?.length
            ? { matchStatuses: matchStatuses.map((opt) => opt.value as PatientCohortMatchWorkflowState) }
            : {}),
          ...filtersForReq,
        };

        break;

      case 'trialStatus':
        filtersForReq = {
          ...filtersForReq,
          trialStatuses: filters.trialStatus
            ?.map((status) => getMappedProgramSiteStatuses(status.value as LfsTrialStatus))
            .flat(),
        };
        break;

      case 'trialType':
        filtersForReq = { ...filtersForReq, trialType: filters.trialType?.value as TrialType };
        //TODO use TrialType elsewhere instead of string
        break;

      case 'physician':
        filtersForReq = { ...filtersForReq, physicianName: filters.physician };
        break;

      case 'biomarker':
        filtersForReq = { ...filtersForReq, biomarker: filters.biomarker };
        break;

      case 'patientName':
        filtersForReq = { ...filtersForReq, patientFullName: filters.patientName };
        break;

      case 'patientId':
        filtersForReq = { ...filtersForReq, patientId: filters.patientId };
        break;

      case 'note':
        filtersForReq = { ...filtersForReq, noteText: filters.note };
        break;

      case 'sortOrder':
        if (tab) {
          sortBy = getSortByForReq(filters.sortOrder?.label as SORT_OPTION);
        }
        break;
      default:
        break;
    }
  });

  return { sortBy, filtersForReq };
};

export const getMatchFlags = (ptd: PatientTrackingDetails) => {
  return [
    ptd.urgentFlag && MATCH_STATUS_FLAGS.URGENT,
    ptd.newFlag && MATCH_STATUS_FLAGS.NEW,
    ptd.cohortOnHoldFlag && MATCH_STATUS_FLAGS.COHORT_ON_HOLD,
    ptd.siteOnHoldFlag && MATCH_STATUS_FLAGS.SITE_ON_HOLD,
    ptd.rescreenFlag && MATCH_STATUS_FLAGS.RESCREEN,
  ].filter(Boolean) as MATCH_STATUS_FLAGS[];
};

const reversedTabForReq = Object.fromEntries(Object.entries(tabForReq).map(([key, value]) => [value, key]));

export const transformTabCounts = (input: Record<Tab, number>): Record<PATIENT_TRACKER_V3_TAB, number> => {
  const result: Partial<Record<PATIENT_TRACKER_V3_TAB, number>> = {};

  for (const [tab, count] of Object.entries(input)) {
    const patientTrackerTab = reversedTabForReq[tab as Tab];
    if (patientTrackerTab) {
      result[patientTrackerTab as PATIENT_TRACKER_V3_TAB] = count;
    }
  }

  return result as Record<PATIENT_TRACKER_V3_TAB, number>;
};
