import { Badge, BadgeProps } from '@tcl-boron-prefabs/badge';
import React from 'react';

import { MATCH_STATUS_FLAG_BADGE_VARIANTS, MATCH_STATUS_FLAGS } from '~/store/patientTrackerCommons/constants';

import { useStyles } from './styles';

interface MatchFlagsProps {
  flags: MATCH_STATUS_FLAGS[];
}

export const MatchFlags: React.FC<MatchFlagsProps> = ({ flags }) => {
  const classes = useStyles();

  return (
    <div className={classes.matchStatusFlags}>
      {flags.map((flag) => (
        <Badge
          key={flag}
          text={flag}
          small
          badgeType="secondary"
          badgeVariant={MATCH_STATUS_FLAG_BADGE_VARIANTS[flag] as BadgeProps['badgeVariant']}
        />
      ))}
    </div>
  );
};

export default MatchFlags;
