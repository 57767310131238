import { SagaIterator } from '@redux-saga/core';
import { PATIENT_TRACKER_STATUS_ROLLUP } from '@tempus/t-shared/src/constants/patient-tracker';
import { storeActions, IMPERSONATION_ERROR_CODE } from '@tempus/t-shared/ui';
import _ from 'lodash';
import { call, put, takeLatest, takeLeading, select, all } from 'redux-saga/effects';

import api from '~/store/api';
import { getPatientTrackerRecordsRequestOptions, parseFilters } from '~/store/patientTrackerCommons/helpers';
import { getCurrentDate } from '~/utils/misc';

import { GetPatientTrackerTabCountsRequestBody } from '../api/types';
import { tabNamesForPage } from '../patientTrackerCommons/constants';
import { creators } from './actions';
import { getFiltersForReq, transformTabCounts } from './helpers';
import {
  GET_PATIENT_TRACKER_V3_RECORDS,
  GET_PATIENT_TRACKER_V3_RECORDS_CSV,
  GET_PATIENT_TRACKER_V3_RECORD,
  GetPatientTrackerRecords,
  GetPatientTrackerRecord,
  GetPatientTrackerRecordsCsv,
  GET_PATIENT_TRACKER_V3_RECORDS_FAILED,
  GET_PATIENT_TRACKER_V3_CATEGORY_COUNTS,
  GetPatientCategoryCounts,
  SHOW_PATIENT_TRACKER_V3_ERROR_MESSAGE,
} from './types';

function* getPatientTrackerRecords({ id: stateMapKey, siteId }: GetPatientTrackerRecords): SagaIterator {
  try {
    const { tab, filters, patientsMetadata } = yield select(({ patientTrackerV3 }) => patientTrackerV3[stateMapKey]);

    const { sortBy, filtersForReq } = getFiltersForReq(siteId, filters, tab);

    const { patients, patientCount } = yield call(api.stateflowPatientTracker.getPatientRecords, {
      filters: filtersForReq,
      sortBy,
      page: patientsMetadata.skip + 1,
      pageSize: patientsMetadata.numRecords,
    });

    const timePatients = _.isEmpty(patients)
      ? []
      : patients.map((patient) => ({
          ...patient,
          patientTrackingDetails: patient.matches.map((match) => ({
            ...match,
            status: match.currentState,
          })),
        }));

    yield put(creators.setPatientTrackerRecords(stateMapKey, timePatients));
    yield put(
      creators.setPatientTrackerPagination(
        stateMapKey,
        patientsMetadata.numRecords,
        patientsMetadata.skip,
        patientCount,
      ),
    );
  } catch (error) {
    yield put(creators.getPatientTrackerRecordsFailed(stateMapKey, error));
  }
}

function* getPatientRecord({ id, timePatientId, siteId }: GetPatientTrackerRecord): SagaIterator {
  yield all([call(getTimePatientData, id, timePatientId, siteId), call(getInstitutionPhysicians, id, timePatientId)]);
}

function* getTimePatientData(stateId: string, timePatientId: string, siteId: string): SagaIterator {
  try {
    const timePatientData = (yield call(api.stateflowPatientTracker.getPatientRecord, siteId, timePatientId))[0]; // TODO: update be to send only the patient data
    yield put(
      creators.setTimePatientData(stateId, {
        ...timePatientData,
        patientTrackingDetails: timePatientData.matches.map((match) => ({ ...match, status: match.currentState })),
      }),
    );
  } catch (error) {
    yield put(creators.getTimePatientDataFailed(stateId, error));
  }
}

function* getInstitutionPhysicians(stateId: string, timePatientId: string): SagaIterator {
  try {
    const physicians = yield call(api.timePatient.getInstitutionPhysicians, timePatientId);
    yield put(creators.setInstitutionPhysicians(stateId, physicians));
  } catch (error) {
    yield put(creators.getInstitutionPhysiciansFailed(stateId, error));
  }
}

function* getPatientRecordsCsv({ id, siteId, opts = {} }: GetPatientTrackerRecordsCsv): SagaIterator {
  try {
    const { tab, filters } = yield select(({ patientTrackerV3 }) => patientTrackerV3[id]);
    const matchStatuses = PATIENT_TRACKER_STATUS_ROLLUP[tab];
    const matchStatusFilter = (filters.matchStatus || []).map((opt) => opt.value);
    const combinedMatchStatus = matchStatusFilter.length
      ? _.intersection(matchStatuses, matchStatusFilter)
      : matchStatuses;
    const { orderBy, filterParams } = parseFilters(filters, opts.customFilterList);

    const data = yield call(api.patientTrackerV2.getPatientRecordsCsv, {
      institutionId: siteId,
      matchStatus: opts.includeAllCategories ? undefined : combinedMatchStatus,
      ...getPatientTrackerRecordsRequestOptions(tab),
      orderBy,
      ...filterParams,
      ...opts,
    });

    const currentDate = getCurrentDate().replace(/\//g, '-');
    const fileName = `TIME_patient_tracker_${siteId}_${currentDate}_${Date.now()}.csv`;

    yield put(creators.setPatientTrackerRecordsCsv(id, fileName, data));
  } catch (error) {
    yield put(creators.getPatientTrackerRecordsCsvFailed(id, error));
  }
}

function* getPatientTrackerCategoryCounts({ id: stateMapKey, siteId }: GetPatientCategoryCounts): SagaIterator {
  try {
    const { filters } = yield select(({ patientTrackerV3 }) => patientTrackerV3[stateMapKey]);

    const { filtersForReq } = getFiltersForReq(siteId, filters);

    const counts = yield call(api.stateflowPatientTracker.getTabCounts, {
      filters: filtersForReq,
      tabNames: tabNamesForPage[stateMapKey],
    } as GetPatientTrackerTabCountsRequestBody);

    yield put(creators.setPatientTrackerCategoryCounts(stateMapKey, transformTabCounts(counts)));
  } catch (error) {
    yield put(creators.getPatientTrackerRecordsFailed(stateMapKey, error));
  }
}

function* putErrorMessage(action): SagaIterator {
  const { error, defaultErrorMessage } = action;
  const { name, response, message } = error;
  const { statusText, data, status } = response || {};
  let errorText = message;

  if (name === IMPERSONATION_ERROR_CODE) {
    errorText = message;
  } else if (status === 409) {
    errorText = data.message;
  } else if (defaultErrorMessage) {
    errorText = defaultErrorMessage;
  } else if (statusText) {
    errorText = `${statusText}: ${data.message || message}`;
  }

  yield put(storeActions.notification.showErrorMessage(errorText));
}

export default [
  takeLeading(GET_PATIENT_TRACKER_V3_RECORDS, getPatientTrackerRecords),
  takeLeading(GET_PATIENT_TRACKER_V3_RECORD, getPatientRecord),
  takeLeading(GET_PATIENT_TRACKER_V3_RECORDS_CSV, getPatientRecordsCsv),
  takeLatest(GET_PATIENT_TRACKER_V3_RECORDS_FAILED, putErrorMessage),
  takeLatest(GET_PATIENT_TRACKER_V3_RECORDS_FAILED, putErrorMessage),
  takeLeading(GET_PATIENT_TRACKER_V3_CATEGORY_COUNTS, getPatientTrackerCategoryCounts),
  takeLatest(SHOW_PATIENT_TRACKER_V3_ERROR_MESSAGE, putErrorMessage),
];
